import React, { Component } from "react";
import { Link } from "gatsby";
import Cookies from "js-cookie";

class SignInOrRegisterButtons extends Component {
  state = {
    loggedIn: false,
  };

  componentDidMount() {
    const isLoggedIn = Cookies.get("isLoggedIn");
    if (isLoggedIn === "true") {
      this.setState({
        loggedIn: true,
      });
    } else {
      this.setState({
        loggedIn: false,
      });
    }
  }

  render() {
    return (
      <>
        {this.state.loggedIn ? (
          <div className="header-btn ms-auto ms-lg-0 d-none d-sm-block">
            <a href="https://app.streaka.com/#/register">
              <button className="btn btn-blue-3 header-btn-2 font-size-3 rounded-5">
                Go To Dashboard
              </button>
            </a>
          </div>
        ) : (
          <>
            <div className="navbar-nav-wrapper">
              <ul className="navbar-nav main-menu nav-buttons me-6 ms-6">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    role="button"
                    aria-expanded="false"
                    to="/contact"
                  >
                    Contact Sales
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    role="button"
                    aria-expanded="false"
                    href="https://app.streaka.com"
                  >
                    Log In
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="header-btn ms-auto ms-lg-0 d-none d-sm-block">
              <a href="https://app.streaka.com/#/register">
                <button className="btn btn-blue-3 btn-sm rounded-5">
                  Try for free &nbsp;{">"}
                </button>
              </a>
            </div> */}
          </>
        )}
      </>
    );
  }
}

export default SignInOrRegisterButtons;
