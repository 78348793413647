import React from "react";
import { Link } from "gatsby";
import { menuItems } from "../Header/menuItems";
import imgLB from "../../assets/image/logo-main-black.png";
import imgLW from "../../assets/image/logo-main-white.png";

const Footer = ({ className, ...rest }) => {
  return (
    <>
      <div
        className={`bg-dark-cloud pt-15 pt-lg-26 pb-9 ${className}`}
        {...rest}
      >
        <div className="container">
          <div className="pb-5 pb-lg-22">
            <div className="row justify-content-lg-between">
              {/* Brand Logo */}
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-7">
                <div className="brand-logo mb-10 mb-lg-0 dark-mode-texts pe-lg-10 pe-xl-0 pt-lg-3">
                  <Link to="/#">
                    <img
                      className="mx-auto mx-0 light-version-logo default-logo"
                      src={imgLB}
                      alt=""
                    />
                    <img
                      src={imgLW}
                      alt=""
                      className="dark-version-logo mx-auto mx-0"
                    />
                  </Link>
                </div>
              </div>
              {/* End Brand Logo */}
              <div className="col-xl-8 col-md-7 offset-lg-1">
                <div className="row">
                  {/* Single Widgets */}
                  <div className="col-sm-6 col-6">
                    <div className="mb-10 mb-lg-0">
                      <ul className="list-unstyled">
                        {menuItems.map(
                          (
                            { label, isExternal = false, name, items, ...rest },
                            index
                          ) => {
                            const hasSubItems = Array.isArray(items);
                            return (
                              <React.Fragment key={name + index}>
                                {hasSubItems ? (
                                  <React.Fragment>
                                    <li className="mb-2">
                                      {isExternal ? (
                                        <a
                                          className="font-size-5 text-lily-white gr-hover-text-dodger-blue-1"
                                          href={`${name}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {label}
                                        </a>
                                      ) : (
                                        <Link
                                          className="font-size-5 text-lily-white gr-hover-text-dodger-blue-1"
                                          to={`/${name}`}
                                          role="button"
                                          aria-expanded="false"
                                        >
                                          {label}
                                        </Link>
                                      )}
                                    </li>
                                    {items.map((subItem, indexSub) => {
                                      return (
                                        <React.Fragment
                                          key={subItem.name + indexSub}
                                        >
                                          <li className="mb-2">
                                            {" "}
                                            {isExternal ? (
                                              <a
                                                className="font-size-5 text-lily-white gr-hover-text-dodger-blue-1"
                                                href={`${subItem.name}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                - {subItem.label}
                                              </a>
                                            ) : (
                                              <Link
                                                className="font-size-5 text-lily-white gr-hover-text-dodger-blue-1"
                                                to={`/${subItem.name}`}
                                                role="button"
                                                aria-expanded="false"
                                              >
                                                - {subItem.label}
                                              </Link>
                                            )}
                                          </li>
                                        </React.Fragment>
                                      );
                                    })}
                                  </React.Fragment>
                                ) : (
                                  <li className="mb-2">
                                    {isExternal ? (
                                      <a
                                        className="font-size-5 text-lily-white gr-hover-text-dodger-blue-1"
                                        href={`${name}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {label}
                                      </a>
                                    ) : (
                                      <Link
                                        className="font-size-5 text-lily-white gr-hover-text-dodger-blue-1"
                                        to={`/${name}`}
                                        role="button"
                                        aria-expanded="false"
                                      >
                                        {label}
                                      </Link>
                                    )}
                                  </li>
                                )}
                              </React.Fragment>
                            );
                          }
                        )}
                        <li>
                          <Link
                            className="font-size-5 text-lily-white gr-hover-text-dodger-blue-1"
                            to='/terms-and-privacy'
                          >
                            Terms & Privacy
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Single Widgets */}
                  {/* Single Widgets */}
                  <div className="col-sm-6 col-8">
                    <div className="mb-10 mb-lg-0 me-xl-12">
                      <div className="mt-7">
                        <ul className="d-flex align-items-center list-unstyled mb-0">
                          {/* social media links here */}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* End Single Widgets */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
